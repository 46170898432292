@use "@/scss/config" as *;

.option_container {
    display: flex;
}

.option {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    max-width: 42em;

    background-color: var(--color-contrast-low);
    border-bottom: 0.4rem solid var(--color-contrast-between_lower_low);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    border-radius: var(--space-sm);
    cursor: pointer;
    user-select: none;

    .content, .change_prompt {
        display: flex;
        flex-direction: row;
    }
    .content {
        flex-grow: 1;
        padding: var(--space-2xs) var(--space-xs);
        @include min-width(sm) {
            padding: var(--space-xs) var(--space-sm);
        }
    }

    .icon {
        height: 100%;
        padding-top: var(--space-2xs);
        padding-right: var(--space-xs);
        transition: transform 0.1s var(--ease-out);
        img {
            height: auto;
            width: 3.5em;
            min-height: 3.5em;
            min-width: 3.5em;
            @include min-width(sm) {
                height: 4.5em;
                width: 4.5em;
                min-height: 4.5em;
                min-width: 4.5em;
            }
        }
        img {
            object-fit: contain;
        }
    }
    .inline_icon {
        display: inline-block;
        height: 1.5em;
        width: 1.5em;
        min-height: 1.5em;
        min-width: 1.5em;
        margin-left: var(--space-2xs);
    }

    &.disabled_option {
        &, &:hover, &.selection_open {
            background-color: var(--color-contrast-between_lower_low);
            border-bottom-color: var(--color-contrast-lower);
        }

        .icon, .inline_icon {
            filter: grayscale(1.0);
            opacity: 0.6;
        }
        .name_and_desc,
        &.selection_open .name_and_desc,
        &.selection .name_and_desc,
        &:hover .name_and_desc {
            .name {
                color: var(--color-contrast-medium);
            }
            .desc {
                color: var(--color-contrast-medium);
            }
        }
    }

    .name_and_desc {
        width: 100%;
        .name {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            width: 100%;
            font-weight: bold;
            font-size: var(--text-lg);
            color: var(--color-contrast-higher);
        }
        .desc {
            font-size: var(--text-base);
            color: var(--color-contrast-high);
        }

        .change_prompt {
            flex-grow: 0;
            align-self: flex-start;
            font-size: var(--text-md);
            font-weight: bold;
            line-height: 1.1em;
            margin-left: auto;
            padding: var(--space-3xs) 0;
            padding-left: var(--space-xs);

            .prompt_arrow {
                fill: var(--color-contrast-high);
                width: var(--text-lg);
                height: var(--text-lg);
                min-width: var(--text-lg);
                min-height: var(--text-lg);
                transform: scale(1.0);
                transition: 0.2s transform ease-in-out;
            }
        }
    }

    &.selection_open .change_prompt .prompt_arrow {
        transform: scale(-1.0);
    }

    &:hover, &.selection_open {
        background-color: var(--color-contrast-below);
        border-bottom-color: var(--color-contrast-low);
    }

    &.selection, &.selection_open {
        .icon {
            transform: scale(1.1);
            transition: transform 0.1s var(--ease-out);
        }
        .name_and_desc .name {
            color: var(--color-contrast-higher);
            font-size: var(--text-lg);
        }
    }
}
